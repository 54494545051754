import React                from 'react';
import { FormattedMessage } from 'react-intl';
import Wrapper              from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Spacer               from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Link                 from '@interness/web-core/src/components/elements/Link/Link';

const NotFoundPage = () => {
  return (
    <Wrapper>
      <Spacer/>
      <h1><FormattedMessage id={'pages.404.header'}/></h1>
      <p><FormattedMessage id={'pages.404.content'}/></p>
      <Link to='/'><FormattedMessage id={'pages.404.link'}/></Link>
      <Spacer/>
    </Wrapper>
  )
};

export default NotFoundPage;
